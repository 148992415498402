import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  useTracking,
  useTrackOnce,
} from '@utilitywarehouse/partner-tracking-react';

import { REQUEST_SENDING } from 'constants/requestStatus';
import { ENERGY_ELECTRICITY, ENERGY_GAS } from 'redux/modules/Energy/constants';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import TilDialog from 'modules/Energy/Details/TilDialog';
import Banner from 'modules/Energy/Banners/SmartMeter';
import { EVENTS } from 'app/lib/analytics/constants';
import useOptlyEvent from 'app/lib/analytics/Optimizely/useOptlyEvent';
import {
  events as optlyEvents,
  features,
  variations,
} from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { EnergyAlert, EnergyAlertQualifier } from '../EnergyAlert';
import TopSwitch from './TopSwitch';
import EnergyCosts from './EnergyCosts';
import Info from './Info';
import EPGAlert from 'modules/Shared/EPGAlert';
import { EVBanner } from '../Banners/EVBanner';
import { EVInterestForm } from '../EVInterestForm';
import { useScrollTo } from 'app/hooks/useScrollTo';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  energyWarning: {
    marginTop: theme.spacing(2),
  },
  epgAlert: {
    marginTop: theme.spacing(3),
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  evSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: -theme.spacing(6),
  },
}));

const EnergyDetails = ({
  acceptQuotes,
  current,
  paymentPlan,
  ledSavings,
  hasDaffodil,
  requestStatus,
  showTilDialog,
  hideTilDialog,
  hasEvInterest,
  showDialog,
  til,
  getQuotes,
  selectedServices,
  isBannerEnabled,
  tariff,
  isTenant,
  history,
  showEnergyOnlyWarning,
  canSwitchTariff,
  smartEligible,
  setEvInterestChecked,
  numberOfSelectedServices,
  ...props
}) => {
  const classes = useStyles();
  const tracking = useTracking();
  const trackingOnce = useTrackOnce();
  const optlyEvent = useOptlyEvent();
  const ref = React.useRef();
  const {
    scrollElementIntoView: scrollEvIntoView,
    elementRef: evRef,
  } = useScrollTo();
  const tariffType = tariff ? tariff.type : null;
  const isVariableSelected = tariffType === TARIFF_TYPES.VARIABLE;
  const isPrepayment = tariffType === TARIFF_TYPES.PREPAYMENT;

  const { variant } = useFeature(features.ENABLE_SMART_METER_BANNER);
  const smartMeterBannerEnabled =
    variant === variations[features.ENABLE_SMART_METER_BANNER].ON;

  const handleAcceptQuote = () => {
    acceptQuotes(current.quoteIds, hasEvInterest);
    tracking.track(EVENTS.ENERGY_SUMMARY_QUOTE_ACCEPTED, {
      ev_tariff_interest_expressed: hasEvInterest,
    });
    optlyEvent(optlyEvents.ENERGY_SUBMITTED);
  };

  const handleToggleTariff = (nextTariffType) => {
    if (canSwitchTariff) {
      getQuotes(paymentPlan, selectedServices, nextTariffType, false);
      tracking.track(EVENTS.ENERGY_SUMMARY_TARIFF_SELECTED, {
        tariff: nextTariffType,
      });
    }
  };

  useScrollIntoView(
    ref,
    ({ visible }) => {
      if (visible) trackingOnce(EVENTS.ENERGY_EV_TARIFF_INFO_DISPLAYED);
    },
    { threshold: 0.8 }
  );

  return (
    <>
      <SectionCentered
        newLayout
        sectionCenteredClasses={{ root: classes.root }}
      >
        {!isPrepayment && current.electricity && (
          <EVBanner
            onFindMoreClick={scrollEvIntoView}
            isVariableSelected={isVariableSelected}
            showSwitchAlert={!isVariableSelected && hasEvInterest}
          />
        )}
        {isVariableSelected && (
          <div className={classes.alertContainer}>
            <EPGAlert classes={{ root: classes.epgAlert }} />
            <EnergyAlert />
          </div>
        )}
        <TopSwitch
          current={current}
          tariffType={tariffType}
          isTenant={isTenant}
          hasDaffodil={hasDaffodil}
          handleToggleTariff={handleToggleTariff}
          numberOfSelectedServices={numberOfSelectedServices}
          {...props}
        />
        {showEnergyOnlyWarning && (
          <Alert
            severity="warning"
            variant="filled"
            classes={{ root: classes.energyWarning }}
          >
            <AlertTitle>Important: deposit required</AlertTitle>
            <Typography>
              Before we can switch your{' '}
              {current.gas && !current.electricity && 'gas service'}
              {current.electricity && !current.gas && 'electricity service'}
              {current.electricity && current.gas && 'energy services'} to UW,
              we will complete a credit check and then require a security
              deposit. Once you’ve completed your application, you’ll be
              prompted to make payment. We’ll then get your switch started.
            </Typography>
          </Alert>
        )}
        {current.electricity && (
          <EnergyCosts
            type={ENERGY_ELECTRICITY}
            quote={current.electricity}
            paymentPlan={paymentPlan}
            ledSavings={ledSavings}
            hasDaffodil={hasDaffodil}
            showTilDialog={showTilDialog}
          />
        )}
        {current.gas && (
          <EnergyCosts
            type={ENERGY_GAS}
            quote={current.gas}
            paymentPlan={paymentPlan}
            showTilDialog={showTilDialog}
          />
        )}
        <Info showSavings={tariffType === TARIFF_TYPES.VARIABLE} />
        {smartMeterBannerEnabled && smartEligible && <Banner />}
        {tariffType === TARIFF_TYPES.VARIABLE && <EnergyAlertQualifier />}
        {showDialog && <TilDialog {...til} closeFn={hideTilDialog} />}
      </SectionCentered>
      {isVariableSelected && current.electricity && (
        <div ref={ref}>
          <SectionCentered
            newLayout
            classes={{ root: classes.evSection }}
            backgroundColor="white"
          >
            <EVInterestForm
              sectionRef={evRef}
              onCheck={setEvInterestChecked}
              checked={hasEvInterest}
            />
          </SectionCentered>
        </div>
      )}
      <NavigationPane
        helpCtaEnabled
        back
        next
        autoFocus="next"
        nextLabel="Accept Quote"
        nextHandler={handleAcceptQuote}
        nextDisabled={requestStatus.acceptQuotes === REQUEST_SENDING}
      />
    </>
  );
};

const quoteSchema = {
  annualConsumption: PropTypes.string.isRequired,
  rawAnnualConsumption: PropTypes.number.isRequired,
  personalProjection: PropTypes.string.isRequired,
  rawPersonalProjection: PropTypes.shape({
    value: PropTypes.number.isRequired,
    exponent: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  tariff: PropTypes.shape({
    showDirectDebitInfo: PropTypes.bool.isRequired,
  }).isRequired,
};

EnergyDetails.propTypes = {
  showEnergyOnlyWarning: PropTypes.bool.isRequired,
  acceptQuotes: PropTypes.func.isRequired,
  current: PropTypes.shape({
    label: PropTypes.string,
    electricity: PropTypes.shape(quoteSchema),
    gas: PropTypes.shape(quoteSchema),
    quoteIds: PropTypes.array,
  }),
  getQuotes: PropTypes.func.isRequired,
  hasDaffodil: PropTypes.bool,
  hasEvInterest: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  paymentPlan: PropTypes.string.isRequired,
  requestStatus: PropTypes.shape({
    acceptQuotes: PropTypes.string,
  }),
  selectedServices: PropTypes.array.isRequired,
  ledSavings: PropTypes.shape({
    energy: PropTypes.number,
    money: PropTypes.string,
  }),
  showTilDialog: PropTypes.func.isRequired,
  setEvInterestChecked: PropTypes.func.isRequired,
  hideTilDialog: PropTypes.func.isRequired,
  tariff: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  isTenant: PropTypes.bool,
  showDialog: PropTypes.bool,
  isBannerEnabled: PropTypes.bool,
  til: PropTypes.shape({}),
  canSwitchTariff: PropTypes.bool.isRequired,
  smartEligible: PropTypes.bool.isRequired,
  numberOfSelectedServices: PropTypes.number.isRequired,
};

export default EnergyDetails;
